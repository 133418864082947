<template>
  <b-container data-aos="fade-up">
    <b-img-lazy class="w-100" alt="" src="../../assets/thaw-area.jpg" />
    <h1 class="mt-5 mb-4 text-primary">Our Area</h1>
    <p>
      <i>
        TH&amp;W provide a community transport service in the villages of
        Thorney, Harby, Wigsley and surrounding villages (N &amp; S Clifton and
        Spalford) to enable residents of all ages to access health related
        services, shopping amenities, leisure and social activities during the
        day or evenings.
      </i>
    </p>
    <div class="w-100 google-map">
      <iframe
        src="https://www.google.com/maps/d/embed?mid=1JCK5USV72azGTUPiYbhOY1Ou2wV2TOs1&hl=en"
        class="h-100 w-100"
      ></iframe>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "OurArea",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.google-map {
  height: 550px;
}
</style>
